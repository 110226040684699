<template>
  <section class="hero is-fullheight" style="background-color: #f1f1f1">
    <vue-headful :title="pageTitle" />
    <div class="hero-body">
      <div class="container" style="width: 99vw">
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div id="login">
              <div v-show="isIE" style="padding: 2rem">&nbsp;</div>
              <div>
                <figure class="has-text-centered">
                  <img :src="activeSite.logoUrl" style="max-width: 250px" />
                </figure>
                <div v-show="isInvalidPass">
                  <i class="fas fa-times has-text-danger"></i>
                  {{ failedLoginMessage }}
                  <br />
                  <br />
                </div>
                <form v-on:keyup.enter="login()">
                  <div class="field">
                    <label class="label is-size-7">Email Address</label>
                    <div class="control">
                      <input
                        :class="
                          ' input ' + (isInvalidPass ? ' is-danger ' : '')
                        "
                        type="email"
                        name="email"
                        v-model="input.email"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <label class="label is-size-7">Password</label>
                    <div class="control">
                      <input
                        :class="'input ' + (isInvalidPass ? ' is-danger' : '')"
                        type="password"
                        name="password"
                        v-model="input.password"
                      />
                    </div>
                  </div>

                  <div class="is-clearfix">
                    <div class="field is-pulled-left">
                      <div class="control">
                        <input
                          type="checkbox"
                          id="rememberMe"
                          name="rememberMe"
                          v-model="input.rememberMe"
                        />
                        <label
                          for="rememberMe"
                          class="is-size-7"
                          style="padding: 0px 7px"
                          >Remember Me for 30 Days</label
                        >
                      </div>
                    </div>
                    <div class="field is-pulled-right">
                      <div class="control">
                        <a
                          class="button is-accent"
                          v-bind:class="{ 'is-loading': isLoggingIn }"
                          v-on:click="login()"
                          >Login</a
                        >
                      </div>
                    </div>
                  </div>
                </form>
                <div class="has-text-centered" style="padding-top: 30px">
                  <p>
                    <a id="lostpwd" href="#" @click="sendPasswordReset"
                      >Lost your password?</a
                    >
                  </p>
                  <p style="padding-bottom: 100px">
                    <a id="backtosite" :href="activeSite.linkedUrl"
                      >← Back to {{ activeSite.displayName }}</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="column"></div>
        </div>
      </div>
    </div>
    <div
      v-show="isIE"
      class="is-size-5"
      style="
        text-align: center;
        backgroundcolor: #e69138;
        color: white;
        padding: 20px;
        position: absolute;
        top: 20px;
        width: 100%;
      "
    >
      <B>Internet Explorer users:</B>&nbsp;&nbsp;For a better client portal
      experience, please use either Google Chrome or Firefox as your browser.
    </div>
    <div
      v-show="isUnsupported"
      class="is-size-5"
      style="
        text-align: center;
        backgroundcolor: #e69138;
        color: white;
        padding: 20px;
        position: absolute;
        top: 20px;
        width: 100%;
      "
    >
      To access the client portal, please use either Google Chrome or Firefox as
      your browser.
    </div>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  updateSession,
  activeSite,
  updateSessionTimeout
} from '../vuex-actions'
import { STAFF } from '../roles'

export default {
  name: 'Login',
  data () {
    return {
      input: {
        email: '',
        password: '',
        rememberMe: false
      },
      pageName: 'Login',
      isInvalidPass: false,
      isLoggingIn: false,
      isUnsupported: false,
      failedLoginMessage: 'Invalid credentials.'
    }
  },
  created () {
    let remMe = this.getCookie('rememberMe')
    if (remMe) {
      this.input.email = decodeURIComponent(remMe)
      this.input.rememberMe = true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return this.pageName + ' - ' + this.activeSite.displayName
    },
    isIE () {
      var ua = navigator.userAgent
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1
    },
    IsUnsupported () {
      if (navigator.appVersion.indexOf('MSIE 10') !== -1) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapMutations([updateSession, updateSessionTimeout]),
    getCookie (name) {
      let match = document.cookie.match(new RegExp(name + '=([^;]+)'))
      if (match) return match[1]
    },
    async login () {
      try {
        this.isLoggingIn = true
        this.isInvalidPass = false

        let route = `/session/login`
        let payload = { ...this.input }
        let response = await this.axios.post(route, payload)

        if (!response.status) {
          response = JSON.parse(response)
        }

        if (
          response &&
          response.data &&
          response.data.userId &&
          response.data.token
        ) {
          this.$router.push({
            name: 'ChangePassword',
            query: {
              userId: response.data.userId,
              resetToken: response.data.token
            }
          })
          return
        }

        if (response && response.status === 401) {
          // eslint-disable-next-line no-throw-literal
          throw new Error().response = response
        }

        // Make sure to show the cookies on every new session
        this.$cookies.remove('isNotificationsClosed')

        try {
          this[updateSession](response.data)
        } catch (error) {
          // console.log(error)
        }
        let nav = { path: !response.data.isMfaValid ? '/2fa-verify' : '' }

        nav.path += '?fromURL=' + this.$router.currentRoute.query.fromUrl

        if (response.data.userRole !== STAFF) {
          nav.path += '&to=MyAccount'
        } else {
          nav.path += '&to=Reports'
        }

        if (response.data.isMfaValid && this.clientSession) {
          nav = { name: this.clientSession.userRole === 'Staff' ? 'staffDashboard' : 'Dashboard' }
        }

        this.$router.push(nav).catch()
      } catch (error) {
        if (error.response && error.response.data && error.response.data._Message) {
          this.failedLoginMessage = error.response.data._Message
        }

        if (
          error.response &&
          error.response.status &&
          error.response.status === 401
        ) {
          this.isInvalidPass = true
        } else {
          this.handleApiErr(error)
        }
      } finally {
        this.isLoading = false
        this.isLoggingIn = false
      }
    },
    async sendPasswordReset () {
      if (!this.input.email) {
        this.successToast('Please enter your email address', null, true)
        return
      }
      try {
        let route = `/user/send-password-reset-email`
        let params = { params: { email: this.input.email } }
        await this.axios.post(route, null, params)
        this.successToast(
          `If an account was found for ${this.input.email}, a reset email will be sent to that address.`
        )
      } catch (error) {
        this.handleApiErr(error)
      } finally {
      }
    }
  }
}
</script>
